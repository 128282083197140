import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Image, PageText, Cards, TextWithImage, CardsWithImage } from '../slices'
import { theme } from '../styles'

const Content = styled.div`
  padding: 4rem 0;
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 2rem 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 1rem 0;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

export default class SliceZone extends Component {
  render() {
    const { allSlices } = this.props
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'text':
          return <PageText key={s.id} input={s} />
        case 'image':
          return <Image key={s.id} input={s} />
        case 'cards':
          return <Cards key={s.id} input={s} />
        case 'text_with_image':
          return <TextWithImage key={s.id} input={s} />
        case 'cards_with_image':
          return <CardsWithImage key={s.id} input={s} />
        default:
          return null
      }
    })
    return <Content>{slice}</Content>
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
}
