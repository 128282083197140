import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Link from '../../Link'
import Categories from './Categories'
import LinkResolver from '../../../gatsby/linkResolver'
import { Amplitude } from '@amplitude/react-amplitude'

const Item = styled.article`
  flex: 0 0 calc(33% - ${props => props.theme.spacing(3)});
  padding-bottom: ${props => props.theme.spacing(2)};

  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 0 0 calc(50% - ${props => props.theme.spacing(2)});
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 0 0 100%;
  }
`

const FeaturedImage = styled(Img)`
  margin-bottom: 0;
`

const Card = styled.div`
  flex: 1;
  margin-bottom: ${props => props.theme.spacing(3)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
`

const Headline = styled(Link)`
  font-style: normal;
  color: ${props => props.theme.colors.black};
  margin: ${props => props.theme.spacing(3)} 0px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: ${props => props.theme.spacing(1)} 0px;
  }
`

const ArticleTitle = styled.h4`
  font-size: 1.4rem;
  display: inline;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.2rem;
  }
`
const ArticleSubtitle = styled.h5`
  font-size: 1.4rem;
  display: inline;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.2rem;
  }
`

const Meta = styled.div`
  font-family: 'TeleGroteskNext-Thin';
  margin-bottom: ${props => props.theme.spacing(2)};

  a {
    color: black;
  }
`

export default class Article extends Component {
  render() {
    const { node, categories } = this.props
    const url = LinkResolver(node)
    return (
      <Item>
        <FeaturedImage
          fluid={node.data.featured_image.localFile.childImageSharp.fluid}
        ></FeaturedImage>
        <Card>
          <Amplitude
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'post card'],
              linkUrl: url,
              linkLabel: node.data.title.text,
              linkType: 'Card',
            })}
          >
            {({ instrument }) => (
              <Headline to={url} onClick={instrument('nav click')}>
                <ArticleTitle>{node.data.title.text}</ArticleTitle>{' '}
                <ArticleSubtitle>{node.data.description}</ArticleSubtitle>
              </Headline>
            )}
          </Amplitude>
          <Meta>
            <Amplitude
              eventProperties={inheritedProps => ({
                ...inheritedProps,
                scope: [...inheritedProps.scope, 'meta'],
              })}
            >
              {node.data.date}
              {categories && (
                <>
                  {' — '}
                  <Categories categories={categories} pid={node.uid} />
                </>
              )}
            </Amplitude>
          </Meta>
        </Card>
      </Item>
    )
  }
}

Article.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
