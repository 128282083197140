import { useState, useEffect } from 'react'

const useWindowWidth = () => {
  /* SSR doesn't know the browser yet, any use of window will fail on build time */
  const browserWindow = typeof window !== 'undefined' && window
  const [width, setWidth] = useState(browserWindow.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(browserWindow.innerWidth)
    browserWindow.addEventListener('resize', handleResize)
    return () => {
      browserWindow.removeEventListener('resize', handleResize)
    }
  }, [browserWindow.innerWidth])

  return width
}

export default useWindowWidth
