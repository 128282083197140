import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { theme } from '../styles'
import Link from './Link'
import Modal from './Modal'
import useModal from './useModal'
import useWindowWidth from './useWindowWidth'

import { Amplitude } from '@amplitude/react-amplitude'

const TopHeader = styled.header`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  background: #fff;
  z-index: 500;

  -webkit-transition: background-color 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
  -ms-transition: background-color 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
  transition: background-color 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 1.2rem;
  }
`

const transparent = css`
  background: transparent;
  box-shadow: none;
`

const shadow = css`
  box-shadow: 0px 0px 10px rgba(204, 204, 204, 0.5);
`

const Container = styled.div`
  max-width: 1440px;
  position: relative;
  padding: 0 2rem;
  margin: ${theme.spacing(2)} auto ${theme.spacing(0)};
  a {
    color: ${props => (props.invert ? theme.colors.greyLight : theme.colors.greyDark)};
    font-weight: 400;
    font-style: normal;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
`

const Title = styled.h1`
  font-size: 1rem;
  line-height: 36px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
`

const Anchor = styled(props => <Link {...props} />)`
  width: 140px;
`
const Logo = styled(Img)`
  margin-bottom: 0;
`

const Navigation = styled.nav`
  a {
    color: ${theme.colors.greyDark};
    font-weight: 400;
    font-style: normal;
  }
`

const NavigationList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-bottom: ${theme.spacing(0.5)};
  li {
    display: inline;
    &:not([data-name='nav-link-0']) {
      margin-left: 2.5rem;
    }
    a {
      font-style: normal;
      color: ${theme.colors.greyDark};
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      &:hover,
      &:focus {
        color: ${theme.colors.black};
        text-decoration: none;
        box-shadow: 0 2px 0 #2b2b2b;
      }
      @media (max-width: ${theme.breakpoints.s}) {
        font-size: 2rem;
        text-transform: none;
        font-weight: 300;
        font-family: 'TeleGroteskNext-Thin';
      }
    }
  }
`

const transparentList = css`
  li {
    a {
      font-style: normal;
      color: white;
      &:hover,
      &:focus {
        color: white;
        box-shadow: 0 2px 0 #f8f8f8;
      }
    }
    span {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`

const HamburguerButton = styled.li`
  span {
    svg {
      path {
        fill: ${theme.colors.black};
      }
    }
  }
`

const VerticalContainer = styled(Container)`
  align-items: flex-start;
  flex-direction: column;
  ul li {
    flex-basis: 100%;
    &:not([data-name='nav-link-0']) {
      margin-left: 0;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: ${theme.spacing(0)};
  right: ${theme.spacing(3)};

  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
  color: #fff;
  border: none;
  padding: ${theme.spacing(1)};
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
    border-bottom: 1px dashed ${theme.colors.grey};
  }

  animation: fade 0.8s 1;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Paragraph = styled.p`
  text-transform: none;
  margin-top: ${theme.spacing(12)};
  padding-right: 50vw;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'TeleGroteskNext-Thin';
`

function Header(props) {
  const { nav, logo, withVideoHeading, threshold } = props

  const showFullMenu = useWindowWidth() > parseInt(theme.breakpoints.s, 10)

  /* To be documented: SSR doesn't know the browser yet, any use of window will fail on build time */
  const browserWindow = typeof window !== 'undefined' && window

  const [transparency, setTransparency] = useState(withVideoHeading)
  const [raised, setShadow] = useState(false)

  const { isShowing, toggle } = useModal()

  /* To be documented: callback pattern to ensure only one listener is attached / cleanup at useEffect + handlers that only run based on dependencies */
  const handleScroll = useCallback(() => {
    const scrollCheck = browserWindow.pageYOffset / browserWindow.innerHeight < threshold
    const shadowCheck = !scrollCheck
    if (scrollCheck !== transparency) {
      if (withVideoHeading) {
        setTransparency(scrollCheck)
      }
    }
    if (shadowCheck !== raised) {
      setShadow(shadowCheck)
    }
  }, [browserWindow.pageYOffset])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <React.Fragment>
      <Amplitude
        eventProperties={inheritedProps => ({
          ...inheritedProps,
          scope: [...inheritedProps.scope, 'header'],
        })}
      >
        <TopHeader
          css={[
            transparency &&
              css`
                ${transparent};
              `,
            raised &&
              css`
                ${shadow};
              `,
          ]}
        >
          <Container>
            <Title>
              <Amplitude
                eventProperties={inheritedProps => ({
                  ...inheritedProps,
                  scope: [...inheritedProps.scope, 'nav link'],
                  linkUrl: '/',
                  linkLabel: 'Ir a la página principal',
                  linkType: 'Logo',
                })}
              >
                {({ instrument }) => (
                  <Anchor
                    to="/"
                    aria-label="Ir a la página principal"
                    onClick={instrument('nav click')}
                  >
                    <Logo alt={logo.alt} fixed={logo.localFile.childImageSharp.fixed}></Logo>
                  </Anchor>
                )}
              </Amplitude>
            </Title>

            <Navigation>
              <NavigationList
                css={
                  transparency &&
                  css`
                    ${transparentList};
                  `
                }
              >
                {showFullMenu ? (
                  nav.edges.map((s, index) => (
                    <li data-name={`nav-link-${index}`} key={s.node.primary.label.text}>
                      <Amplitude
                        eventProperties={inheritedProps => ({
                          ...inheritedProps,
                          scope: [...inheritedProps.scope, 'nav link'],
                          linkUrl: s.node.primary.link.url,
                          linkLabel: s.node.primary.label.text,
                          linkType: 'Text',
                        })}
                      >
                        {({ instrument }) => (
                          <Link
                            to={s.node.primary.link.url}
                            target={s.node.primary.link.target}
                            onClick={instrument('nav click')}
                          >
                            {s.node.primary.label.text}
                          </Link>
                        )}
                      </Amplitude>
                    </li>
                  ))
                ) : (
                  <Amplitude
                    eventProperties={inheritedProps => ({
                      ...inheritedProps,
                      scope: [...inheritedProps.scope, 'nav menu'],
                    })}
                  >
                    {({ instrument }) => (
                      <HamburguerButton>
                        <span>
                          <svg
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            role="img"
                            aria-labelledby="svg-menu-title svg-menu-desc"
                            onClick={instrument('menu open', toggle)}
                          >
                            <title id="svg-menu-title">Menú</title>
                            <desc id="svg-menu-desc">Abrir el menú de navegación</desc>
                            <path d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z" />
                            <path
                              d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </HamburguerButton>
                    )}
                  </Amplitude>
                )}
              </NavigationList>
            </Navigation>
          </Container>
        </TopHeader>
        <Modal
          isShowing={isShowing}
          hide={toggle}
          modalId="sidebar-nav-menu"
          closeId="sidebar-nav-menu-close"
        >
          <VerticalContainer>
            <Title>
              <Logo alt={logo.alt} fixed={logo.localFile.childImageSharp.fixed}></Logo>
            </Title>
            <Amplitude
              eventProperties={inheritedProps => ({
                ...inheritedProps,
                scope: [...inheritedProps.scope, 'nav menu'],
              })}
            >
              {({ instrument }) => (
                <CloseButton
                  type="button"
                  data-dismiss="modal"
                  aria-label="Cerrar"
                  onClick={instrument('menu close', toggle)}
                  id="sidebar-nav-menu-close"
                >
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    role="img"
                    aria-labelledby="svg-menu-close-title svg-menu-close-desc"
                    onClick={toggle}
                  >
                    <title id="svg-menu-close-title">Cerrar</title>
                    <desc id="svg-menu-close-desc">Cerrar el menú de navegación</desc>
                    <path
                      fill="black"
                      d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"
                    />
                  </svg>
                </CloseButton>
              )}
            </Amplitude>
            <NavigationList>
              {nav.edges.map((item, index) => (
                <li data-name={`nav-link-${index}`} key={item.node.id}>
                  <Amplitude
                    eventProperties={inheritedProps => ({
                      ...inheritedProps,
                      scope: [...inheritedProps.scope, 'nav link'],
                      linkUrl: item.node.primary.link.url,
                      linkLabel: item.node.primary.label.text,
                      linkType: 'Text',
                    })}
                  >
                    {({ instrument }) => (
                      <Link
                        to={item.node.primary.link.url}
                        target={item.node.primary.link.target}
                        onClick={instrument('nav click', toggle)}
                      >
                        {item.node.primary.label.text}
                      </Link>
                    )}
                  </Amplitude>
                </li>
              ))}
            </NavigationList>
            <Paragraph>
              Impulsamos la transformación digital de las administraciones para desarrollar mejores
              ciudades
            </Paragraph>
          </VerticalContainer>
        </Modal>
      </Amplitude>
    </React.Fragment>
  )
}

export default Header

Header.propTypes = {
  nav: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  withVideoHeading: PropTypes.bool,
  threshold: PropTypes.number.isRequired,
}

Header.defaultProps = {
  withVideoHeading: false,
}
