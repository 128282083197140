import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { theme } from '../styles'

const Content = styled.section`
  padding-bottom: 2rem;
  margin-left: ${theme.spacing(19)};
  max-width: ${theme.maxWidthText};
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin-left: ${theme.spacing(3)};
    & pre {
      white-space: pre-wrap;
      font-size: 0.75rem;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

const CodeBlock = ({ input }) => (
  <Content dangerouslySetInnerHTML={{ __html: input.primary.code_block.html }} />
)

export default CodeBlock

CodeBlock.propTypes = {
  input: PropTypes.object.isRequired,
}
