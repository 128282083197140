import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const PreviewableImage = ({ image, placeholderWidth, placeholderHeight, minFluidHeight  }) => {
  const { alt, localFile, url } = image
  return !localFile ? (
    <img
      width={placeholderWidth ? `${placeholderWidth}px` : '100%'}
      height={placeholderHeight ? `${placeholderHeight}px` : 'unset'}
      alt={alt}
      src={url}
      loading="lazy"
      style={{ objectFit: 'cover', objectPosition: '50% 50%' }}
    />
  ) : (
    <Img fluid={localFile.childImageSharp.fluid} style={{ minHeight: minFluidHeight }}/>
  )
}

export default PreviewableImage

PreviewableImage.propTypes = {
  placeholderWidth: PropTypes.number,
  placeholderHeight: PropTypes.number,
  minFluidHeight: PropTypes.string,
  image: PropTypes.object.isRequired,
}

PreviewableImage.defaultProps = {
  placeholderWidth: null,
  placeholderHeight: null,
  minFluidHeight: null,
}
