import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import PreviewableImage from '../components/Preview/PreviewableImage'
import { theme } from '../styles'
import { HTMLRenderer as ParseHTML } from '../components/ParseHTML'
import Link from '../components/Link'
import ActionLink from './ActionLink'
import useWindowWidth from '../components/useWindowWidth'
import { Amplitude } from '@amplitude/react-amplitude'

const Content = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: ${theme.spacing(6)} ${theme.spacing(12)} 0;
  padding-bottom: ${theme.spacing(6)};

  @media (max-width: ${theme.breakpoints.l}) {
    margin: ${theme.spacing(6)} ${theme.spacing(6)} 0;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    max-width: ${theme.maxWidthText};

    margin: ${theme.spacing(4)} auto 0;
    padding-bottom: ${theme.spacing(4)};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;

    padding-bottom: ${theme.spacing(2)};
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

const ImageWrapper = styled.div`
  flex: 0 0 calc(60% - ${theme.spacing(3)});
  padding: ${props => (props.aspectRatio < 0.75 ? `0 15%` : `0`)};
  @media (max-width: ${theme.breakpoints.m}) {
    flex: 0 0 calc(40% - ${theme.spacing(3)});
    padding: ${props => (props.aspectRatio < 0.75 ? `0 5%` : `0`)};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: ${props =>
      props.aspectRatio < 0.75
        ? `${theme.spacing(3)} 40% ${theme.spacing(3)} 0`
        : `${theme.spacing(3)} 15% ${theme.spacing(3)} 0`};
  }
`

const TextWrapper = styled.div`
  flex: 0 0 calc(40% - ${theme.spacing(3)});
  padding: ${props =>
    props.paddingDirection === 'Right' ? `0 ${theme.spacing(3)} 0 0` : `0 0 0 ${theme.spacing(3)}`};
  @media (max-width: ${theme.breakpoints.m}) {
    flex: 0 0 calc(60% - ${theme.spacing(3)});
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 0;
  }
`

const SectionTitle = styled.h3`
  font-size: 3rem;
  margin-bottom: ${theme.spacing(1)};
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 2.5rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 2rem;
  }
`

const SectionSubtitle = styled.p`
  font-size: 2.4rem;
  margin-bottom: ${theme.spacing(1)};
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 2rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 1.75rem;
  }
`

const AnchorReplacement = ({ href, ...props }) => <Link to={href} {...props} />

function TextWithImage(props) {
  const { input } = props
  const isSmallDevice = useWindowWidth() <= parseInt(theme.breakpoints.s, 10)
  return (
    <Content>
      <Amplitude
        eventProperties={inheritedProps => ({
          ...inheritedProps,
          scope: [...inheritedProps.scope, 'text-with-image'],
          content: input.primary.text.text,
        })}
      >
        {(input.primary.image_position === 'Left' || isSmallDevice) && (
          <ImageWrapper
            aspectRatio={
              input.primary.image.localFile
                ? input.primary.image.localFile.childImageSharp.fluid.aspectRatio
                : input.primary.image.dimensions.width / input.primary.image.dimensions.height
            }
          >
            <PreviewableImage image={input.primary.image} />
          </ImageWrapper>
        )}
        <TextWrapper paddingDirection={input.primary.image_position}>
          {input.primary.section_heading.text && (
            <SectionTitle>{input.primary.section_heading.text}</SectionTitle>
          )}
          {input.primary.section_subtitle && (
            <SectionSubtitle>{input.primary.section_subtitle}</SectionSubtitle>
          )}
          <ParseHTML
            html={input.primary.text.html}
            components={{
              a: AnchorReplacement,
            }}
          />
          {input.primary.action_label && (
            <ActionLink
              actionLink={input.primary.action_link}
              actionLabel={input.primary.action_label}
              actionType={input.primary.action_type}
            />
          )}
        </TextWrapper>
        {input.primary.image_position === 'Right' && !isSmallDevice && (
          <ImageWrapper
            aspectRatio={
              input.primary.image.localFile
                ? input.primary.image.localFile.childImageSharp.fluid.aspectRatio
                : input.primary.image.dimensions.width / input.primary.image.dimensions.height
            }
          >
            <PreviewableImage image={input.primary.image} />
          </ImageWrapper>
        )}
      </Amplitude>
    </Content>
  )
}

export default TextWithImage

TextWithImage.propTypes = {
  input: PropTypes.object.isRequired,
}

AnchorReplacement.propTypes = {
  href: PropTypes.string.isRequired,
}
