import styled from '@emotion/styled'
import { theme } from '../styles'

const Title = styled.h2`
  font-size: 3rem;
  font-family: 'TeleGroteskNext-Thin';
  font-weight: 300;
  text-align: center;
  position: relative;
  @media (max-width: ${theme.breakpoints.l}) {
    font-size: 2.5rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 2rem;
  }
`

export default Title
