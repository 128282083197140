import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ThemeContext } from '@emotion/core'
import { Carousel } from 'react-responsive-carousel'
import Article from './Article'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import WindowWidthContext from '../../WindowWidthContext'
import { carouselSection } from '../Carousel'

const Section = styled.section`
  margin-top: 2rem;
  margin-bottom: 4rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const CarouselSection = styled.section`
  ${carouselSection};
`

export default function List(props) {
  const { posts } = props
  const theme = useContext(ThemeContext)
  const showSlider = useContext(WindowWidthContext) <= parseInt(theme.breakpoints.s, 10)
  return !showSlider ? (
    <Section id="noticias">
      {posts.map(post => {
        let categories = false
        if (post.node.data.categories[0].category.document) {
          categories = post.node.data.categories.map(c => c.category.document)
        }
        return <Article key={post.node.uid} node={post.node} categories={categories} />
      })}
    </Section>
  ) : (
    <React.Fragment>
      <CarouselSection>
        <Carousel
          swipeScrollTolerance={100}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          {posts.map(post => {
            let categories = false
            if (post.node.data.categories[0].category.document) {
              categories = post.node.data.categories.map(c => c.category.document)
            }
            return <Article key={post.node.uid} node={post.node} categories={categories} />
          })}
        </Carousel>
      </CarouselSection>
    </React.Fragment>
  )
}

List.propTypes = {
  posts: PropTypes.array.isRequired,
}
