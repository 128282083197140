import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../../../styles'

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  z-index: 200;
  margin-bottom: ${theme.spacing(1)};
  justify-content: center;
  li {
    display: inline;
    flex: 0 0 calc(14% - ${theme.spacing(3)});
    line-height: 0.7;
    padding: ${theme.spacing(2)};
    strong {
      color: ${theme.colors.greyLight};
      font-family: 'TeleGroteskNext-Ultra';
      font-size: 2rem;
    }
    em {
      color: ${theme.colors.greyLight};
      font-family: 'TeleGroteskNext-Thin';
      font-size: 0.9em;
      font-style: normal;
      // text-transform: uppercase;
      margin-left: ${theme.spacing(0.5)};
    }
    @media (max-width: ${theme.breakpoints.m}) {
      flex: 0 0 calc(25% - ${theme.spacing(3)});
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    li {
      flex: 0 0 calc(25% - ${theme.spacing(2)});
      padding: ${theme.spacing(1)};
      strong {
        font-size: 1.6rem;
      }
    }
    li:nth-of-type(n + 5) {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    li {
      text-align: center;
    }
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    li {
      flex: 0 0 calc(33% - ${theme.spacing(2)});
      padding: ${theme.spacing(1)};
      strong {
        font-size: 1.3rem;
      }
    }
    li:nth-of-type(n + 4) {
      display: none;
    }
  }
`

function FiguresStructure(props) {
  const { keyFigures } = props
  return (
    <List>
      {keyFigures.map((figure, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`figures-${index}`}>
          <strong>{figure.value}</strong>
          <em>{figure.description}</em>
        </li>
      ))}
    </List>
  )
}

function Figures(props) {
  return (
    <StaticQuery
      query={graphql`
        query FiguresQuery {
          figures: prismicHomepage {
            data {
              key_figures {
                description
                value
              }
            }
          }
        }
      `}
      render={data => (
        <FiguresStructure {...props} keyFigures={data.figures.data.key_figures}></FiguresStructure>
      )}
    />
  )
}

export default Figures

FiguresStructure.propTypes = {
  keyFigures: PropTypes.array.isRequired,
}
