import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, pathname, article, page, node }) => {
  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      orgLogo,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: banner || `${siteUrl}${defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrganization = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'T-Systems Iberia',
    legalName: 'T-Systems ITC Iberia, SAU',
    url: 'https://www.t-systems.es',
    logo: {
      '@type': 'ImageObject',
      url: `${siteUrl}${orgLogo}`,
    },
    foundingDate: '1996',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Sancho de Ávila 110-130',
      addressLocality: 'Barcelona',
      addressRegion: 'Barcelona',
      postalCode: '08018',
      addressCountry: 'Spain',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      telephone: '[+34-93-501-5000]',
    },
    sameAs: [
      'https://www.youtube.com/user/TSystemsIberia',
      'https://www.instagram.com/tsystems_iberia/',
      'https://www.linkedin.com/company/t--systems-iberia',
      'https://www.facebook.com/tsystemsiberia',
      'https://twitter.com/TSystems_Iberia',
    ],
  }

  const schemaWebsite = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: siteUrl,
    name: defaultTitle,
    description: defaultDescription,
    headline,
    creator: {
      '@type': 'Organization',
      name: author,
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${orgLogo}`,
      },
    },
    publisher: {
      '@type': 'Organization',
      name: author,
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${orgLogo}`,
      },
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: author,
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${orgLogo}`,
      },
    },
    datePublished: '2019-09-01T10:30:00+01:00',
    dateModified: buildTime,
    inLanguage: siteLanguage,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      headline: seo.title,
      description: seo.description,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      author: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${orgLogo}`,
        },
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${orgLogo}`,
        },
      },
      copyrightHolder: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${orgLogo}`,
        },
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': seo.url,
      },
      datePublished: node.first_publication_date,
      dateCreated: node.first_publication_date,
      dateModified: node.last_publication_date,
      inLanguage: siteLanguage,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  let schemaPage = null

  if (page) {
    schemaPage = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: seo.title,
      description: seo.description,
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${orgLogo}`,
        },
      },
      copyrightHolder: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${orgLogo}`,
        },
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': seo.url,
      },
      datePublished: node.first_publication_date,
      dateCreated: node.first_publication_date,
      dateModified: node.last_publication_date,
      inLanguage: siteLanguage,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (page/article) + everytime (breadcrumbs & website & organization) */}
        <script type="application/ld+json">{JSON.stringify(schemaWebsite)}</script>
        <script type="application/ld+json">{JSON.stringify(schemaOrganization)}</script>
        {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        {page && <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  page: PropTypes.bool,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  page: false,
  node: null,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        orgLogo
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`
