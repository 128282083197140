import { useState, useEffect } from 'react'

const useAmplitude = () => {
  const [amplitudeInstance, setInstance] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('amplitude-js').then(amplitude => {
        setInstance(amplitude.getInstance())
      })
    }
  }, [])

  return amplitudeInstance
}

export default useAmplitude
