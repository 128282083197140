import styled from '@emotion/styled'
import { theme } from '../styles'

const Subtitle = styled.h3`
  font-size: 2rem;
  font-family: 'TeleGroteskNext-Thin';
  font-weight: 300;
  text-align: center;
  position: relative;

  @media (max-width: ${theme.breakpoints.l}) {
    font-size: 1.8rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
`

export default Subtitle
