import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ThemeContext } from '@emotion/core'
import { Carousel } from 'react-responsive-carousel'
import Highlight from './Highlight'

import useWindowWidth from '../../useWindowWidth'
import WindowWidthContext from '../../WindowWidthContext'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { carouselSection } from '../Carousel'

const Section = styled.section`
  margin-top: 2rem;
  margin-bottom: 4rem;

  padding: 0 4rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    justify-content: space-between;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 0;
  }
`

const CarouselSection = styled.section`
  ${carouselSection};
`

export default function List(props) {
  const theme = useContext(ThemeContext)
  const showSlider = useContext(WindowWidthContext) <= parseInt(theme.breakpoints.xs, 10)
  const { highlights } = props
  return !showSlider ? (
    <Section>
      {highlights.map(highlight => (
        <Highlight key={highlight.id} slice={highlight.primary} />
      ))}
    </Section>
  ) : (
    <CarouselSection>
      <Carousel swipeScrollTolerance={100} showThumbs={false} showStatus={false} showArrows={false}>
        {highlights.map(highlight => (
          <Highlight key={highlight.id} slice={highlight.primary} />
        ))}
      </Carousel>
    </CarouselSection>
  )
}

List.propTypes = {
  highlights: PropTypes.array.isRequired,
}
