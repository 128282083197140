import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { PostText, CodeBlock, Image, Quote } from '../slices'
import { theme, prism } from '../styles'

const Content = styled.div`
  ${prism};
  padding: 6rem 0;
  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
  }
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 4rem 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem 0;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

export default class PostSliceZone extends Component {
  render() {
    const { allSlices } = this.props
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'text':
          return <PostText key={s.id} input={s} />
        case 'code_block':
          return <CodeBlock key={s.id} input={s} />
        case 'image':
          return <Image key={s.id} input={s} />
        case 'quote':
          return <Quote key={s.id} input={s} />

        default:
          return null
      }
    })
    return <Content>{slice}</Content>
  }
}

PostSliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
}
