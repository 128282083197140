import { css } from '@emotion/core'
import { theme } from '../../../styles/index'

const carouselSection = css`
.carousel__dot-group {
    text-align: center;
    margin-top: ${theme.spacing(2)};    
  }
  .carousel .slide {
    background: transparent;
    text-align: unset;
    display: flex;
  }
  .carousel .control-dots {
    position: relative;
    border-bottom: 1px solid ${theme.colors.greyLight};
    padding-bottom: ${theme.spacing(2)}; 
    margin: ${theme.spacing(2)} 20% 0;
    width: auto;
    .dot {
      background: background: ${theme.colors.white};
      border: 1px solid ${theme.colors.grey};
      border-radius: ${theme.spacing(1)};
      padding: ${theme.spacing(0.5)};
      margin: ${theme.spacing(1)};
      cursor: pointer;
      box-shadow: none;
    }
    .dot.selected {
      background: ${theme.colors.grey};
    }
  }
`

export default carouselSection
