import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '../../Link'
import LinkResolver from '../../../gatsby/linkResolver'
import { Amplitude } from '@amplitude/react-amplitude'

export default class Categories extends Component {
  render() {
    const { categories, pid } = this.props
    return (
      <>
        {categories.map((cat, i) => (
          <React.Fragment key={`${pid}-${cat.data.name}`}>
            {!!i && ', '}
            <Amplitude
              eventProperties={inheritedProps => ({
                ...inheritedProps,
                scope: [...inheritedProps.scope, 'category link'],
                linkUrl: LinkResolver(cat),
                linkLabel: cat.data.name,
                linkType: 'Text',
              })}
            >
              {({ instrument }) => (
                <Link to={LinkResolver(cat)} onClick={instrument('nav click')}>
                  {cat.data.name}
                </Link>
              )}
            </Amplitude>
          </React.Fragment>
        ))}
      </>
    )
  }
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  pid: PropTypes.string.isRequired,
}
