const theme = {
  colors: {
    primary: '#3D63AE',
    bg: '#fff',
    black: '#000',
    white: '#fff',
    link: '#00739F',
    greyLighter: '#f8f9fa',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#595C62',
    greyDark: '#303643',
    greyDarker: '#1c252b',
    magenta: '#e20074',
  },
  maxWidth: '1440px',
  maxWidthText: '720px',
  spacing: factor => `${8 * factor}px`,
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
    xl: '1800px',
  },
}

export default theme
