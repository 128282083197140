/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'

import Header from './Header'
import Footer from './Footer'
import SEO from './SEO'
import SkipNavLink from './SkipNavLink'
import { theme, reset } from '../styles'

import '../fonts/TeleGroteskNext.css'

import useWindowWidth from './useWindowWidth'
import { WindowWidthProvider } from './WindowWidthContext'

import useAmplitude from './useAmplitude'

// amplitude-js no está preparada para SSR, lo extraemos a un Hook y cargamos el provider condicionalmente https://github.com/amplitude/Amplitude-JavaScript/issues/110
// import amplitude from 'amplitude-js'
import { AmplitudeProvider } from '@amplitude/react-amplitude'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const globalStyle = css`
  ${reset}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.link};
  }
  a {
    color: ${theme.colors.link};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-style: normal;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.black};
  }
  p,
  li {
    font-size: 1.2rem;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 2rem;
    font-weight: 300;
    font-family: 'TeleGroteskNext-Regular';
  }
  h4 {
    font-size: 1.6rem;
  }
  h5 {
    font-size: 1.6rem;
    font-weight: 300;
    font-family: 'TeleGroteskNext-Regular';
  }
  h6 {
    font-size: 1.3rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    h1 {
      font-size: 2.4rem;
    }
    h2 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 1.6rem;
    }
    h4 {
      font-size: 1.28rem;
    }
    h5 {
      font-size: 1.28rem;
    }
    h6 {
      font-size: 1.15rem;
    }
    p,
    li {
      font-size: 1rem;
    }
  }
`

// Hack to use Amplitude with SSR

const amplitudeApiKey = 'f003c109f265ce3a0bb3f17601910293'

function InstrumentedLayout(props) {
  const amplitude = useAmplitude()

  return amplitude ? (
    <AmplitudeProvider amplitudeInstance={amplitude} apiKey={amplitudeApiKey}>
      {props.children}
    </AmplitudeProvider>
  ) : (
    props.children
  )
}

InstrumentedLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

const PureLayout = ({ children, data, customSEO, withVideoHeading, threshold }) => (
  <ThemeProvider theme={theme}>
    <>
      <InstrumentedLayout>
        <Global styles={globalStyle} />
        <SkipNavLink />
        {!customSEO && <SEO />}
        <Header
          withVideoHeading={withVideoHeading}
          logo={data.prismicHomepage.data.logo}
          nav={data.allPrismicNavLinksBodyLinkItem}
          threshold={threshold}
        />
        {children}
        <Footer>
          <div dangerouslySetInnerHTML={{ __html: data.prismicHomepage.data.footer.html }} />
        </Footer>
      </InstrumentedLayout>
    </>
  </ThemeProvider>
)

function Layout(props) {
  const windowWidth = useWindowWidth()

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          prismicHomepage {
            data {
              footer {
                html
              }
              logo {
                alt
                url
                localFile {
                  childImageSharp {
                    fixed(height: 36, quality: 90) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
          allPrismicNavLinksBodyLinkItem {
            edges {
              node {
                id
                primary {
                  label {
                    text
                  }
                  link {
                    url
                    target
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <WindowWidthProvider value={windowWidth}>
          <PureLayout {...props} data={data}>
            {props.children}
          </PureLayout>
        </WindowWidthProvider>
      )}
    />
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  data: PropTypes.object.isRequired,
  customSEO: PropTypes.bool,
  withVideoHeading: PropTypes.bool,
  threshold: PropTypes.number,
}

PureLayout.defaultProps = {
  customSEO: false,
  withVideoHeading: false,
  threshold: 0.1,
}
