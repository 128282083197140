import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'
import { HTMLRenderer as ParseHTML } from '../components/ParseHTML'
import Link from '../components/Link'
import ActionLink from './ActionLink'
import { Amplitude } from '@amplitude/react-amplitude'

const Content = styled.section`
  max-width: ${theme.maxWidthText};

  margin: ${theme.spacing(6)} auto 0;
  padding-bottom: ${theme.spacing(6)};

  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin: ${theme.spacing(4)} auto 0;
    padding-bottom: ${theme.spacing(4)};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding-bottom: ${theme.spacing(2)};
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

const SectionTitle = styled.h3`
  font-size: 3rem;
  margin-bottom: ${theme.spacing(1)};
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 2.5rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 2rem;
  }
`

const SectionSubtitle = styled.p`
  font-size: 2.4rem;
  margin-bottom: ${theme.spacing(1)};
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 2rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 1.75rem;
  }
`

const AnchorReplacement = ({ href, ...props }) => <Link to={href} {...props} />

const PageText = ({ input }) => (
  <Content>
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'page-text'],
        content: input.primary.text.text,
      })}
    >
      {input.primary.section_heading.text && (
        <SectionTitle>{input.primary.section_heading.text}</SectionTitle>
      )}
      {input.primary.section_subtitle && (
        <SectionSubtitle>{input.primary.section_subtitle}</SectionSubtitle>
      )}
      <ParseHTML
        html={input.primary.text.html}
        components={{
          a: AnchorReplacement,
        }}
      />
      {input.primary.action_label && (
        <ActionLink
          actionLink={input.primary.action_link}
          actionLabel={input.primary.action_label}
          actionType={input.primary.action_type}
        />
      )}
    </Amplitude>
  </Content>
)

export default PageText

PageText.propTypes = {
  input: PropTypes.object.isRequired,
}

AnchorReplacement.propTypes = {
  href: PropTypes.string.isRequired,
}
