import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import PreviewableImage from '../components/Preview/PreviewableImage'
import { theme } from '../styles'

const Content = styled.section`
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  @media (max-width: ${theme.breakpoints.m}) {
    padding-top: 0rem;
    padding-bottom: 1rem;
  }
`

const Image = ({ input }) => (
  <Content>
    <PreviewableImage image={input.primary.image} />
  </Content>
)

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
