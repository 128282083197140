import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Link from '../../Link'
import { Amplitude } from '@amplitude/react-amplitude'

const Item = styled.article`
  flex: 0 0 calc(25% - ${props => props.theme.spacing(3)});
  padding-bottom: ${props => props.theme.spacing(2)};

  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 0 0 calc(50% - ${props => props.theme.spacing(3)});
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 0 0 100%;
  }
`

const FeaturedImage = styled(Img)`
  margin-bottom: 0;
`

const FeaturedIcon = styled(Img)`
  margin: 0 auto;
`

const Card = styled.div`
  flex: 1;
  margin-bottom: ${props => props.theme.spacing(3)};
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 ${props => props.theme.spacing(6)};
    border-bottom: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 0 ${props => props.theme.spacing(0)};
  }
`

const Wrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 ${props => props.theme.spacing(12)};
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 0 ${props => props.theme.spacing(6)};
  }
`

const Name = styled.h4`
  display: inline;
  font-size: 1.4rem;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.2rem;
  }
`

const Summary = styled.h5`
  flex-grow: 1;
  display: inline;
  font-size: 1.4rem;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
  }
`

const Meta = styled.div`
  font-family: 'TeleGroteskNext-Thin';
  margin-bottom: ${props => props.theme.spacing(2)};

  a {
    color: black;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
`

const ReadMore = styled(Link)`
  font-style: normal;
  color: ${props => props.theme.colors.black};
  margin: ${props => props.theme.spacing(3)} 0px;
  :hover {
    text-decoration: none;
  }
`

const Strong = styled.strong`
  padding: ${props => props.theme.spacing(2)} 0 0 ${props => props.theme.spacing(2.5)};
  transition: padding 0.2s ease;
  :after {
    content: '→';
    padding-left: ${props => props.theme.spacing(0.5)};
    color: ${props => props.theme.colors.white};
  }
  :hover,
  :focus {
    :after {
      color: ${props => props.theme.colors.greyDark};
    }
  }
`

export default class Product extends Component {
  render() {
    const { slice } = this.props
    return (
      <Item>
        <Card>
          <Wrapper>
            <FeaturedIcon fixed={slice.icon.localFile.childImageSharp.fixed}></FeaturedIcon>
            <Name>{slice.name.text}</Name> <Summary>{slice.summary.text}</Summary>
            <Meta>
              <Amplitude
                eventProperties={inheritedProps => ({
                  ...inheritedProps,
                  scope: [...inheritedProps.scope, 'product card', 'meta'],
                  linkUrl: slice.link.url,
                  linkLabel: `Más información: ${slice.name.text}`,
                  linkType: 'Card',
                })}
              >
                {({ instrument }) => (
                  <ReadMore
                    to={slice.link.url}
                    target={slice.link.target}
                    onClick={instrument('nav click')}
                  >
                    <Strong>Más información</Strong>
                  </ReadMore>
                )}
              </Amplitude>
            </Meta>
          </Wrapper>
          <FeaturedImage fluid={slice.screenshot.localFile.childImageSharp.fluid}></FeaturedImage>
        </Card>
      </Item>
    )
  }
}

Product.propTypes = {
  slice: PropTypes.object.isRequired,
}
