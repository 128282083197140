import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { theme } from '../../../styles'

import Wrapper from '../../Wrapper'
import Figures from './Figures'

const Section = styled.section`
  position: relative;
  height: 75vh;
  min-height: 600px;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: ${theme.spacing(4)} 0;
`

const Video = styled.video`
  min-height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  flex: 1;
`

const PosterWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Poster = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  display: flex;
`

const HeroWrapper = styled(Wrapper)`
  position: relative;
  width: 100%;
  top: 10vh;
  text-align: center;
  padding: 0 5vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
  }
  @media (max-width: ${theme.breakpoints.s}) {
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

const HeroText = styled.div`
  z-index: 100;

  text-align: center;
  margin-bottom: 3rem;
  & h2 {
    color: ${theme.colors.magenta};
    font-size: 5vw;
    font-family: 'TeleGroteskNext-Thin';
    font-weight: 300;
    & strong {
      font-weight: 300;
      font-family: 'TeleGroteskNext-Ultra';
      color: ${theme.colors.magenta};
      & em {
        color: ${theme.colors.white};
        font-family: 'TeleGroteskNext-Thin';
        border: 2px dotted;
        text-decoration: none;
        background: rgba(255, 255, 255, 0.45);
      }
    }
    & em {
      font-style: normal;
      color: ${theme.colors.magenta};
      font-family: 'TeleGroteskNext-Bold';
      text-decoration: underline;
      text-decoration-color: ${theme.colors.black};
    }
    @media (max-width: ${theme.breakpoints.xl}) {
      font-size: 6vw;
    }
    @media (max-width: ${theme.breakpoints.l}) {
      font-size: 7vw;
    }
    @media (max-width: ${theme.breakpoints.m}) {
      font-size: 8vw;
    }
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 3.3rem;
    }
    @media (max-width: ${theme.breakpoints.xs}) {
      font-size: 2.8rem;
    }
  }
`

export default class VideoHero extends Component {
  render() {
    const { video, poster, content } = this.props
    return (
      <React.Fragment>
        <Section>
          <Container>
            <HeroWrapper>
              <HeroText dangerouslySetInnerHTML={{ __html: content.html }} />
            </HeroWrapper>
            <PosterWrapper>
              <Poster
                css={css`
                  background: url(${poster}) center center / cover no-repeat;
                `}
              >
                <Video autoPlay muted loop playsInline>
                  <source src={video} type="video/mp4" />
                </Video>
              </Poster>
            </PosterWrapper>
          </Container>
          <Figures />
        </Section>
      </React.Fragment>
    )
  }
}

VideoHero.propTypes = {
  video: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
}
