import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { ThemeContext } from '@emotion/core'

import WindowWidthContext from '../../WindowWidthContext'

const JumpTo = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-bottom: ${props => props.theme.spacing(1)};
  li {
    display: inline;
    flex-grow: 1;
    text-align: center;
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-family: 'TeleGroteskNext-Thin';
      :after {
        content: '→';
        padding-left: ${props => props.theme.spacing(0.5)};
        color: ${props => props.theme.colors.white};
      }
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
        :after {
          color: ${props => props.theme.colors.greyDark};
        }
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

export default function QuickNav() {
  const theme = useContext(ThemeContext)
  const showNav = useContext(WindowWidthContext) > parseInt(theme.breakpoints.s, 10)
  return (
    showNav && (
      <JumpTo>
        <li>
          <a href="#noticias">Noticias</a>
        </li>
        <li>
          <a href="#adjudicaciones">Adjudicaciones</a>
        </li>
        <li>
          <a href="#productos">Productos</a>
        </li>
        <li>
          <a href="/#servicios">Servicios inteligentes</a>
        </li>
        <li>
          <a href="#smartcities">Plataforma de ciudad inteligente</a>
        </li>
        <li>
          <a href="#formacion">Formación</a>
        </li>
      </JumpTo>
    )
  )
}
