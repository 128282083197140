import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import styled from '@emotion/styled'
import { theme } from '../styles'

const Shadow = styled.div`
  background: #e20074;
  opacity: 0.85;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  animation: fade 0.5s 1;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.85;
    }
  }
`

const Overlay = styled.div`
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: calc(100vw - ${theme.spacing(6)});
  height: 100vh;
  overflow: hidden;

  animation: slide 0.5s forwards;

  @keyframes slide {
    0% {
      width: 60vw;
    }
    100% {
      calc(100vw - ${theme.spacing(6)});
    }
  }
`

function Modal(props) {
  const { isShowing, hide, closeId, modalId } = props

  const rootContainer = useRef(null)
  const modalContainer = useRef(null)
  const exitButton = useRef(null)

  useEffect(() => {
    rootContainer.current = document.querySelector(`#___gatsby`)
    modalContainer.current = document.querySelector(`#${modalId}`)
    exitButton.current = document.querySelector(`#${closeId}`)

    const capturePosition = () => {
      const cachedPosition = window.pageYOffset
      return {
        freeze: () => {
          document.body.style = `position: fixed; top: ${cachedPosition * -1}px; width: 100%;`
        },
        unfreeze: () => {
          document.body.removeAttribute('style')
          window.scrollTo({
            top: cachedPosition,
          })
        },
      }
    }

    const toggleTabIndex = (type, container) => {
      const focusableElements = container.querySelectorAll('button, a, input, textarea, select')
      focusableElements.forEach(element => {
        if (type === 'on') {
          element.removeAttribute('tabindex')
        } else {
          element.setAttribute('tabindex', '-1')
        }
      })
    }

    const handleKeyDown = e => {
      if (e.key === 'Escape') {
        hide()
      }
    }

    const { freeze, unfreeze } = capturePosition()

    if (isShowing) {
      if (exitButton.current) exitButton.current.focus()
      if (modalContainer.current) toggleTabIndex('on', modalContainer.current)
      if (rootContainer.current) toggleTabIndex('off', rootContainer.current)
      window.addEventListener('keydown', handleKeyDown)
      freeze()
    } else {
      if (modalContainer.current) toggleTabIndex('off', modalContainer.current)
      if (rootContainer.current) toggleTabIndex('on', rootContainer.current)
      window.removeEventListener('keydown', handleKeyDown)
      unfreeze()
      /* if (focusAfterExit) focusAfterExit.focus()

       if (!initialRender.current) {
        initialRender.current = true
        setTimeout(() => {
          if (modalContainer) toggleTabIndex('off', modalContainer)
        }, 0)
      } */
    }

    return () => {
      if (isShowing) {
        window.removeEventListener('keydown', handleKeyDown)
        unfreeze()
      }
    }
  }, [isShowing])

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Shadow onClick={hide}></Shadow>
          <Overlay id={modalId}>{props.children}</Overlay>
        </React.Fragment>,
        document.body
      )
    : null
}
export default Modal
