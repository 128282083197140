import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'
import Link from '../components/Link'

import { Amplitude } from '@amplitude/react-amplitude'

const Anchor = styled(Link)`
  :hover {
    text-decoration: none;
  }
`

const Button = styled.button`
  background-color: rgb(252, 241, 19);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  //font-size: 14px;
  //font-weight: 500;
  font-family: TeleGroteskNext-Bold;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(252, 241, 19);
  border-image: initial;
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  margin: 0px 16px 0px 0px;
  :hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: black;
    cursor: pointer;
  }
`

const SecondaryButton = styled.button`
  background-color: white;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  //font-size: 14px;
  //font-weight: 500;
  font-family: TeleGroteskNext-Bold;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(252, 241, 19);
  border-image: initial;
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  margin: 0px 16px 0px 0px;
  :hover {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: black;
    cursor: pointer;
  }
`

const Strong = styled.strong`
  padding: ${theme.spacing(2)} 0;
  margin: ${theme.spacing(2)} ${theme.spacing(4)} 0px 0px;
  color: black;
  :after {
    content: '→';
    padding-left: ${theme.spacing(0.5)};
    transition: padding 0.2s ease;
  }
  :hover:after {
    padding-left: ${theme.spacing(2)};
  }
`

function ActionLink(props) {
  const { actionLink, actionLabel, actionType, ...rest } = props
  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'action link'],
        linkUrl: actionLink.url,
        linkLabel: actionLabel,
        linkType: actionType,
      })}
    >
      {({ instrument }) => (
        <Anchor to={actionLink.url} onClick={instrument('button click')}>
          {actionType === 'Hyperlink' && <Strong {...rest}>{`${actionLabel}`}</Strong>}
          {actionType === 'Primary button' && <Button {...rest}> {`${actionLabel}`}</Button>}
          {actionType === 'Secondary button' && (
            <SecondaryButton {...rest}> {`${actionLabel}`}</SecondaryButton>
          )}
        </Anchor>
      )}
    </Amplitude>
  )
}

export default ActionLink

ActionLink.propTypes = {
  actionLink: PropTypes.object.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
}
