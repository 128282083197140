import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'
import { HTMLRenderer as ParseHTML } from '../components/ParseHTML'
import Link from '../components/Link'

const Content = styled.section`
  max-width: ${theme.maxWidthText};
  margin-left: ${theme.spacing(16)};
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin-left: 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

const AnchorReplacement = ({ href, ...props }) => <Link to={href} {...props} />

const PostText = ({ input }) => (
  <Content>
    <ParseHTML
      html={input.primary.text.html}
      components={{
        a: AnchorReplacement,
      }}
    />
  </Content>
)

export default PostText

PostText.propTypes = {
  input: PropTypes.object.isRequired,
}

AnchorReplacement.propTypes = {
  href: PropTypes.string.isRequired,
}
