import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'

const BlockQuote = styled.blockquote`
  font-family: 'TeleGroteskNext-Thin';
  border-left: 2px solid ${theme.colors.black};
  padding-left: 1.45rem;
  & p {
    font-size: 2rem;
    @media (max-width: ${theme.breakpoints.m}) {
      font-size: 1.5rem;
    }
  }
`
const Content = styled.section`
  padding-bottom: 2rem;
  margin-left: ${theme.spacing(16)};
  max-width: ${theme.maxWidthText};
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin-left: 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`
const Quote = ({ input }) => (
  <Content>
    <BlockQuote dangerouslySetInnerHTML={{ __html: input.primary.quote.html }} />
  </Content>
)

export default Quote

Quote.propTypes = {
  input: PropTypes.object.isRequired,
}
