import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Link from '../../Link'
import { Amplitude } from '@amplitude/react-amplitude'

const Item = styled.article`
  flex: 0 0 calc(25% - ${props => props.theme.spacing(3)});
  padding-bottom: ${props => props.theme.spacing(2)};
  
  display: flex;
  flex-direction: column;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 0 0 calc(50% - ${props => props.theme.spacing(3)});
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 0 0 100%});
  }
`

const Card = styled.div`
  margin-bottom: ${props => props.theme.spacing(3)};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: ${props => props.theme.spacing(1)};
    padding: 0 ${props => props.theme.spacing(3)};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 ${props => props.theme.spacing(3)};
    text-align: center;
  }
`

const Headline = styled(Link)`
  font-style: normal;
  color: ${props => props.theme.colors.black};
  margin: 0px 0px ${props => props.theme.spacing(3)};
`

const Customer = styled.h4`
  font-size: 1.4rem;
  display: inline;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.2rem;
  }
`
const Title = styled.h5`
  font-size: 1.4rem;
  display: inline;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.2rem;
  }
`

const Value = styled.div`
  font-family: 'TeleGroteskNext-Ultra';
  font-size: 3rem;
  a {
    color: black;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 2.5rem;
    line-height: 1;
  }
`

const Currency = styled.span`
  font-size: 1.5rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.3rem;
  }
`

export default class Contract extends Component {
  render() {
    const { node } = this.props
    return (
      <Item>
        <Card>
          <Value>
            {node.data.value.text}
            <Currency>M€</Currency>
          </Value>
          <Amplitude
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'contract card'],
              linkUrl: node.data.link.url,
              linkLabel: `${node.data.customer.text}: ${node.data.title.text}`,
              linkType: 'Card',
            })}
          >
            {({ instrument }) => (
              <Headline to={node.data.link.url} onClick={instrument('nav click')}>
                <Customer>{node.data.customer.text}</Customer> <Title>{node.data.title.text}</Title>
              </Headline>
            )}
          </Amplitude>
        </Card>
      </Item>
    )
  }
}

Contract.propTypes = {
  node: PropTypes.object.isRequired,
}
