import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Item = styled.article`
  flex: 0 0 calc(16% - ${props => props.theme.spacing(1)});
  padding-bottom: ${props => props.theme.spacing(2)};

  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex: 0 0 calc(33% - ${props => props.theme.spacing(2)});
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 0 0 calc(50% - ${props => props.theme.spacing(2)});
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    flex: 0 0 100%;
  }
`

const Card = styled.div`
  flex: 1;
  margin-bottom: ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(3)};

  background: ${props => props.theme.colors.greyLighter};
  box-shadow: 0px 2px 2px ${props => props.theme.colors.greyLight};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: ${props => props.theme.spacing(2)};
  }
`

const Headline = styled.div`
  flex-grow: 1;
  margin: 0px 0px ${props => props.theme.spacing(3)};
`

const Title = styled.h4`
  font-family: 'TeleGroteskNext-Regular';
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 0;
`
const Meta = styled.div`
  font-family: 'TeleGroteskNext-Thin';
  line-height: 1rem;
`

const Badge = styled.div`
  font-family: 'TeleGroteskNext-Bold';
  font-size: 0.6rem;
  line-height: 1rem;
  text-transform: uppercase;
  display: inline-block !important;
  white-space: nowrap !important;

  padding: ${props => props.theme.spacing(0.3)} ${props => props.theme.spacing(0.9)};
  margin-bottom: ${props => props.theme.spacing(2)};

  background-color: ${props => props.theme.colors.black};
  border-radius: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.colors.white};
`

export default class Training extends Component {
  render() {
    const { node } = this.props
    return (
      <Item>
        <Card>
          <Headline>
            <Badge>{node.data.type}</Badge> <Title>{node.data.title.text}</Title>
          </Headline>
          <Meta>{node.data.target.text}</Meta>
        </Card>
      </Item>
    )
  }
}

Training.propTypes = {
  node: PropTypes.object.isRequired,
}
