module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  title: 'www.tao.es - Soluciones para la administración pública', // Full title
  titleAlt: 'www.tao.es', // Short Title for composition

  url: 'https://next.tao.es', // Domain of your site. No trailing slash!

  siteLanguage: 'es', // Language Tag on <html> element
  ogLanguage: 'es_ES', // Facebook Language

  // SEO / JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'tao.es', // shortname for manifest. MUST be shorter than 12 characters
  author: 'T-Systems Iberia', // Author for schemaORGJSONLD
  themeColor: '#e20074',
  backgroundColor: '#EBEDF2',
  logo: '/logos/logo-1024.png', // Used for SEO
  orgLogo: '/logos/TSY_Logo_3c_p.png', // Used for SEO
  headline: 'Noticias e información sobre nuestras soluciones digitales', // Website headline for schema.org JSONLD
  description: 'Creamos soluciones para impulsar la digitalización de la administración pública',

  // Share feature accounts
  twitter: '@TSystems_Iberia', // Twitter Username
  facebook: 'tsystemsiberia', // Facebook Site Name

  // Tracking
  googleAnalyticsID: 'UA-15201290-7',
  amplitudeApiKey: 'f003c109f265ce3a0bb3f17601910293',

  // Recaptcha
  siteRecaptchaKey: '6LdL_rIUAAAAAOTVc2HKyD4f4FB-E8jEpJNFI3Bo',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
