import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ThemeContext } from '@emotion/core'
import { Carousel } from 'react-responsive-carousel'
import Training from './Training'
import ActionLink from '../../../slices/ActionLink'
import WindowWidthContext from '../../WindowWidthContext'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { carouselSection } from '../Carousel'

const Section = styled.section`
  margin-top: 2rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Actions = styled.div`
  margin-bottom: 4rem;
  text-align: center;
`

const CarouselSection = styled.section`
  ${carouselSection};
`

export default function List(props) {
  const { trainings } = props
  const link = { url: '#', target: '_blank', link_type: 'Web' }
  const theme = useContext(ThemeContext)
  const showSlider = useContext(WindowWidthContext) <= parseInt(theme.breakpoints.xs, 10)
  return !showSlider ? (
    <React.Fragment>
      <Section>
        {trainings.map(training => (
          <Training key={training.node.id} node={training.node} />
        ))}
      </Section>
      <Actions>
        <ActionLink actionLink={link} actionLabel="Ver más" actionType="Secondary button" />
      </Actions>
    </React.Fragment>
  ) : (
    <CarouselSection>
      <Carousel swipeScrollTolerance={100} showThumbs={false} showStatus={false} showArrows={false}>
        {trainings.map(training => (
          <Training key={training.node.id} node={training.node} />
        ))}
      </Carousel>
    </CarouselSection>
  )
}

List.propTypes = {
  trainings: PropTypes.array.isRequired,
}
