import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import PreviewableImage from '../components/Preview/PreviewableImage'
import { theme } from '../styles'
import { HTMLRenderer as ParseHTML } from '../components/ParseHTML'
import Link from '../components/Link'
import ActionLink from './ActionLink'
import { Amplitude } from '@amplitude/react-amplitude'

const Content = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: ${theme.spacing(6)} ${theme.spacing(12)} 0;
  padding-bottom: ${theme.spacing(6)};

  @media (max-width: ${theme.breakpoints.l}) {
    margin: ${theme.spacing(6)} ${theme.spacing(6)} 0;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    max-width: ${theme.maxWidthText};

    margin: ${theme.spacing(4)} auto 0;
    padding-bottom: ${theme.spacing(4)};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding-bottom: ${theme.spacing(2)};
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    margin: ${theme.spacing(4)} ${theme.spacing(4)} 0;
    display: block;
  }
`

const Card = styled.article`
  flex: 0 0
    calc(${props => (props.cardsPerRow ? 100 / props.cardsPerRow : 50)}% - ${theme.spacing(3)});
  padding-bottom: ${theme.spacing(6)};
  @media (max-width: ${theme.breakpoints.m}) {
    padding-bottom: ${theme.spacing(6)};
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding-bottom: ${theme.spacing(4)};
    flex: 0 0 calc(50% - ${theme.spacing(3)});
  }
`

const CardTitle = styled.h4`
  font-size: 1.7rem;
  margin-bottom: ${theme.spacing(1)};
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 1.3rem;
  }
`

const CardSubtitle = styled.p`
  font-size: 1.4rem;
  margin-bottom: ${theme.spacing(1)};
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 1.3rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 1.2rem;
  }
`

const ImageWrapper = styled.div`
  width: 75%;
  padding-right: ${props => (props.aspectRatio < 0.75 ? `30%` : `0`)};
  padding-bottom: ${theme.spacing(2)};
  @media (max-width: ${theme.breakpoints.s}) {
    width: 60%;
    padding: ${props =>
      props.aspectRatio < 0.75 ? `0 25% ${theme.spacing(2)} 0` : `0 0 ${theme.spacing(2)}`};
  }
`

const AnchorReplacement = ({ href, ...props }) => <Link to={href} {...props} />

const cardKey = (index, sectionId) => `${sectionId}-card-${index}`

const Cards = ({ input }) => (
  <Content>
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'cards'],
      })}
    >
      {input.items.map((card, index) => (
        <Card cardsPerRow={input.primary.cards_per_row} key={cardKey(index, input.id)}>
          <Amplitude
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'card'],
              content: card.card_content.text,
            })}
          >
            {card.card_image.url && (
              <ImageWrapper
                aspectRatio={
                  card.card_image.localFile
                    ? card.card_image.localFile.childImageSharp.fluid.aspectRatio
                    : card.card_image.dimensions.width / card.card_image.dimensions.height
                }
              >
                <PreviewableImage image={card.card_image} />
              </ImageWrapper>
            )}
            {card.card_title.text && <CardTitle>{card.card_title.text}</CardTitle>}
            {card.card_subtitle && <CardSubtitle>{card.card_subtitle}</CardSubtitle>}
            <ParseHTML
              html={card.card_content.html}
              components={{
                a: AnchorReplacement,
              }}
            />
            {card.card_action_link_label && (
              <ActionLink
                actionLink={card.card_action_link}
                actionLabel={card.card_action_link_label}
                actionType={card.card_action_link_type}
              />
            )}
          </Amplitude>
        </Card>
      ))}
    </Amplitude>
  </Content>
)

export default Cards

Cards.propTypes = {
  input: PropTypes.object.isRequired,
}

AnchorReplacement.propTypes = {
  href: PropTypes.string.isRequired,
}
