const kebabCase = require('lodash/kebabCase')

const linkResolver = doc => {
  // URL for a post type
  if (doc.type === 'post') {
    return `/post/${doc.uid}`
  }
  // URL for a category type
  if (doc.type === 'category') {
    return `/publicaciones/${kebabCase(doc.slugs)}`
  }
  // URL for a post type
  if (doc.type === 'page') {
    return `/page/${doc.uid}`
  }
  // Backup for all other types
  return '/'
}

module.exports = linkResolver
